<template>
    <multi-select :value="selectedUser"
                  @input="updateValue"
                  :options="users"
                  :multiple="false"
                  :close-on-select="true"
                  track-by="id"
                  :clear-on-select="false"
                  :preserve-search="false"
                  label="prenom"
                  :custom-label="textUsername"
                  :show-labels="false"
                  :preselectFirst="addClujiOption"
                  :allow-empty="false"/>
</template>

<script>
    const MultiSelect = () => import('@/components/MultiSelect');

    import {textUsername} from '@/util/text';
    import {apiPath}      from '@/util/http';

    export default {
        name: "UtilisateurMultiselect",
        components: {MultiSelect},
        props: {
            addClujiOption: {
                type: Boolean,
                default: false
            },
            addEmptyOption: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            selectedUser: null,
            users: [],
            clujiOption: {
                id: 0,
                prenom: 'Cluji',
                nom: '',
                pseudo: null
            },
            emptyOption: {
                id: 0,
                prenom: ' ',
                nom: ' ',
                pseudo: null
            }
        }),
        methods: {
            textUsername,
            loadUsers() {
                this.axios.get(apiPath('list_clujistes_quickview'))
                    .then(response => {
                        if (this.addClujiOption)
                            this.users = [this.clujiOption, ...response.data];
                        else if (this.addEmptyOption)
                            this.users = [this.emptyOption, ...response.data];
                        else
                            this.users = response.data;
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des utilisateurs'));
            },
            updateValue(value) {
                this.selectedUser = value;
                this.$emit('input', value);
            }
        },
        mounted() {
            this.loadUsers();
            if (this.value) {
                this.selectedUser = this.value;
            } else if (this.addClujiOption) {
                this.selectedUser = this.clujiOption;
            }
        }
    }
</script>
